import React from 'react';
import { css } from '@emotion/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import scholarlyReport from '../docs/FY17_Scholarly Report.pdf';
import SEO from '../components/seo';
import Layout from '../components/layout';
import NewsAccordion from '../components/newsAccordion';

const yellowHeading = css`
  background: rgb(249, 200, 3);
  line-height: 44px;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
`;

const asideH4 = css`
  background: rgb(192, 192, 192);
  padding: 10px;
`;

const asideAnchor = css`
  display: block;
  margin: 5px 0;
`;

const otherNews = css`
  border-left: 1px solid #ccc;
  padding: 0 10px;
`;

const eventContact = css`
  margin-top: 96px;
`;

const classContent = css`
  padding: 0 10px;
`;

//eslint-disable-next-line
function Announcements() {
  return (
    <Layout>
      <SEO title='Hot Topics/News' />
      <div className='grid-container'>
        <h2 css={yellowHeading}>Hot Topics/News</h2>
        <div className='grid-row'>
          <div className='desktop:grid-col-9'>
            <section css={classContent}>
              <div>
                <NewsAccordion />
                <div css={eventContact}>
                  <h4>
                    Need more information about Hot Topics/News/Announcements, Contact us:
                  </h4>
                  <address>
                    US Army Medical Center of Excellence <br />
                    Public Affairs Office, Directorate of Communications (DCOMM) <br />
                    2250 Stanley Road | JBSA Fort Sam Houston, TX 78234 <br />
                  </address>
                  <a href='mailto:usarmy.jbsa.medcom-ameddcs.mbx.dcomm@mail.mil'>
                    usarmy.jbsa.medcom-ameddcs.mbx.dcomm@mail.mil
                  </a>

                  <p>
                    Do you have a request for information (RFI)? <br />
                    Contact{' '}
                    <a href='mailto:medcoe_requestinfo@army.mil'>
                      medcoe_requestinfo@army.mil
                    </a>
                  </p>
                </div>
              </div>
            </section>
          </div>

          <div className='desktop:grid-col-3'>
            <aside css={otherNews}>
              <h4 css={asideH4}>MEDCoE News</h4>
              <a css={asideAnchor} href={scholarlyReport}>
                Latest Graduate School Scholarly Activity Report
              </a>
              <h4 css={asideH4}>JBSA News</h4>
              <a
                className='usa-external-link'
                css={asideAnchor}
                href='http://www.jbsa.mil/News/News/'
                target='_blank'
              >
                JBSA News <FaExternalLinkAlt />
              </a>
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Announcements;
