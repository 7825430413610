import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import useNews from '../hooks/use-news';

const accordionButton = css`
  margin: 3px 0;
  &:active,
  &:focus {
    outline: dotted 2px !important;
    outline-offset: 2px !important;
  }
`;

const newTag = css`
  background-color: green;
`;

export default function NewsAccordion() {
  const allNews = useNews();
  const distinctMonthYears = Array.from(
    new Set(allNews.map(monthYear => monthYear.groupByDate))
  ).map(gD => {
    return {
      groupByDate: gD,
      accordionDate: allNews.find(displayDate => displayDate.groupByDate === gD)
        .accordionDate
    };
  });

  return (
    <div className='usa-accordion margin-top-2'>
      {allNews.length > 0 ? (
        distinctMonthYears.map(event => (
          <div key={event.groupByDate}>
            <button
              id={`btn_${event.groupByDate}`}
              className='usa-accordion__button'
              aria-expanded='false'
              aria-controls={`ctl_${event.groupByDate}`}
              css={accordionButton}
            >
              {event.accordionDate}
            </button>
            <div
              id={`ctl_${event.groupByDate}`}
              className='usa-accordion__content'
              hidden={true}
            >
              {allNews
                .filter(group => group.groupByDate === event.groupByDate)
                .map(innerevent => (
                  <React.Fragment key={innerevent.id}>
                    {new Date() >
                      new Date(
                        new Date(innerevent.startDate) - 14 * 24 * 60 * 60 * 1000
                      ) && new Date() < new Date(innerevent.endDate) ? (
                      <span className='usa-tag' css={newTag}>NEW</span>
                    ) : null}
                    <span className='text-bold'>{innerevent.name}</span>
                    <br />
                    {innerevent.displayDate}
                    <br />
                    <Link
                      key={innerevent.id}
                      to={`/news/${innerevent.name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')}`}
                    >
                      Click Here for more Info...
                    </Link>
                    <hr />
                  </React.Fragment>
                ))}
            </div>
          </div>
        ))
      ) : (
        <p>Sorry no news, please check back later.</p>
      )}
    </div>
  );
}
