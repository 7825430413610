import { graphql, useStaticQuery } from 'gatsby';

const UseEvents = () => {
  const data = useStaticQuery(graphql`
    query NewsPageQuery {
      allMedcoeEventAncmtJson(
        filter: { Portlet_ID: { eq: 36 } }
        sort: { fields: Event_Date, order: DESC }
      ) {
        nodes {
          EvAnGUID
          GroupByDate: Event_Date(formatString: "YYYYMM")
          AccordionDate: Event_Date(formatString: "YYYY MMMM")
          Event
          DisplayDate: Event_Date(formatString: "DD-MMM-YYYY")
          Event_Date
          Event_Date_End
        }
      }
    }
  `);

  return data.allMedcoeEventAncmtJson.nodes.map(event => ({
    id: event.EvAnGUID,
    groupByDate: event.GroupByDate,
    accordionDate: event.AccordionDate,
    name: event.Event,
    displayDate: event.DisplayDate,
    startDate: event.Event_Date,
    endDate: event.Event_Date_End
  }));
};

export default UseEvents;
